body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  display: inline-block;
  padding: .3em;
  font-family: 'Megrim', cursive;
  position: absolute;
  top: 5%;
  left: 50%;
  user-select: none;
  transform: translate(-50%,-50%);
  color: rgba(255,255,255,.68);
  border: .075em solid rgba(255,255,255,.1);
  font-size: 4em;
  z-index: 150;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
